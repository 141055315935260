import i18n from '@/i18n'

export default {
  state: {
    availableLocales: {
      en: 'English',
      et: 'Estonian',
      ru: 'Russian'
    },
    currentLocale: sessionStorage.getItem('locale') || 'en'
  },

  actions: {
    setLocale ({ state, commit, dispatch }, locale) {
      if (locale !== state.currentLocale) {
        i18n.i18next.changeLanguage(locale)
        sessionStorage.setItem('locale', locale)
        commit('SET_LOCALE', { locale: locale })
        dispatch('classifiers/loadClassifiers', null, { root: true })
      }
    }
  },

  mutations: {
    SET_LOCALE (state, { locale }) {
      state.currentLocale = locale
    }
  }
}
