// Storing in variable a context with all files in this folder
// ending with `.js`.
const requireModule = require.context('.', false, /\.js$/)
let modules = {}

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return
  // filter fullstops and extension
  // create a dynamic object with all modules
  modules = {
    ...modules,
    ...requireModule(fileName).default
  }
})

export default modules
