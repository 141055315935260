import Vue from 'vue'
import FiConfirmModal from '@/components/FiConfirmModal'

const clickHandler = function (e, el, { value: { title, loader, message, ok, cancel = () => {} } = {} }, vnode) {
  e.preventDefault()
  e.stopPropagation()
  e.stopImmediatePropagation()

  const node = document.createElement('div')
  vnode.elm.appendChild(node)

  const DialogConstructor = Vue.extend(FiConfirmModal)

  const component = new DialogConstructor({
    parent: vnode.context,
    propsData: {
      title,
      loader,
      message,
      okCallback: ok,
      hiddenCallback: function () {
        cancel()
        Vue.nextTick(() => {
          component.$el.remove()
          component.$destroy()
        })
      }
    }
  })
  component.$mount(node)
}

Vue.directive('fi-confirm', {
  bind (el, binding, vnode) {
    el.FiConfirm = {}
    el.FiConfirm.clickHandler = e => clickHandler(e, el, binding, vnode)
    el.addEventListener('click', el.FiConfirm?.clickHandler, true)
  },

  unbind (el) {
    el.removeEventListener('click', el.FiConfirm?.clickHandler)
    delete el.FiConfirm
  }
})
