import { connection } from './connection'

export default {
  acceptOffer ({ data }) {
    return connection.post(`/portal/public/application/${data.id}/accept-offer`, { taskId: data.taskId })
  },
  completeSigning ({ data }) {
    return connection.get(`/portal/public/contract/${data.id}/complete-signing`, {
      params: {
        taskId: data.taskId,
        applicationId: data.applicationId
      }
    })
  }
}
