export default {
  common: {
    yes: 'Да',
    no: 'Нет',
    edit: 'Изменить',
    save: 'Сохранить',
    send: 'Отправить',
    remove: 'Удалить',
    activate: 'Активировать',
    removing: 'Удаляется',
    cancel: 'Отмена',
    back: 'Назад',
    close: 'Закрыть',
    active: 'Активен',
    inactive: 'Не активен',
    selectBlank: 'Please select',
    default: 'По умолчанию',
    min: 'Мин',
    max: 'Макс',
    step: 'Шаг',
    formatDate: '{{ value, date }}',
    formatDatetime: '{{ value, datetime }}',
    formatTime: '{{ value, time }}',
    enabled: 'Включено',
    disabled: 'Выключено',
    required: 'Заполните это поле',
    emailError: 'Укажите корректный email адрес',
    apply: 'Apply',
    download: 'Скачать',
    confirm: 'Подтвердить',
    add: '+ Создать'
  },

  auth: {
    title: 'Вход',
    prompt: 'Войдите в свой аккаунт',
    login: 'Логин',
    password: {
      title: 'Пароль',
      username: 'Имя пользователя',
      password: 'Пароль',
      forgot: 'Забыли учетные данные?'
    },
    card: {
      title: 'ID-Card',
      p: 'Insert ID-Card into the card reader and press "Login"'
    },
    mobile: {
      title: 'Mobile-ID',
      p1: 'Your control code is: {{code}}',
      p2: 'Message sent to your telephone. Check the code!',
      phone: 'Mobile number'
    },
    smart: {
      title: 'Smart-ID',
      p1: 'Your control code is: {{code}}',
      p2: 'Message sent to your telephone. Check the code!',
      idCode: 'ID Code'
    },
    idLogout: {
      title: 'Warning',
      warning: 'Restart your browser!'
    }
  },

  menu: {
    search: 'Поиск...',
    home: 'Главная',
    dashboard: 'Дашборд',
    applications: 'Заявки',
    application: 'Заявка',
    applicationNew: 'Новая заявка',
    customers: 'Клиенты',
    customer: 'Клиент',
    customerNew: 'Новый клиент',
    loans: 'Займы',
    loan: 'Займ',
    loanNew: 'Новый займ',
    factoringContracts: 'Факторинг контракты',
    factoringInvoices: 'Факторинг счета',
    factoringScheduleInvoices: 'Schedule invoices',
    factoring: 'Факторинг контракт',
    factoringNew: 'Новый факторинг контракт',
    deposits: 'Депозиты',
    deposit: 'Депозит',
    depositNew: 'Создать депозит',
    debts: 'Задолженности',
    scoring: 'Рейтинг',
    scoringModels: 'Модели рейтинга',
    scoringModelConfigs: 'Конфигурация модели',
    scoringRequest: 'Запрос',
    newScoringRequest: 'Новый запрос',
    payments: 'Платежи',
    payment: 'Платеж',
    paymentsIncoming: 'Входящие платежи',
    paymentsOutgoing: 'Исходящие платежи',
    reports: 'Отчеты',
    reportsFinancial: 'Финансовые отчеты',
    reportsQuicksight: 'BI Dashboards',
    admin: 'Админ',
    products: 'Продукты',
    newProduct: 'Новый продукт',
    editProduct: 'Редактирование продукта',
    classifiers: 'Классификаторы',
    settings: 'Настройки',
    templates: 'Шаблоны',
    newTemplate: 'Новый шаблон',
    editTemplate: 'Редактировать шаблон',
    logs: 'Журналы',
    dataAccessLog: 'Журнал доступа к данным',
    loginHistoryLog: 'Журнал входов в систему',
    roleList: 'Роли',
    logout: 'Выход',
    systemNotifications: 'Системные уведомления',
    automaticNotifications: 'Автоматические уведомления',
    newAutomaticNotification: 'Новое автоматическое уведомление',
    editAutomaticNotification: 'Редактировать автоматическое уведомление',
    migration: 'Миграции данных',
    taxation: 'Taxation',
    newTaxation: 'New tax rate',
    editTaxation: 'Edit tax rate',
    customFields: 'Custom fields',
    customerFields: 'Customer fields',
    KYCCustomFields: 'KYC fields',
    newKYCCustomField: 'New KYC field',
    editKYCCustomField: 'Edit KYC field',
    newCustomerField: 'New customer field',
    editCustomerField: 'Edit customer field',
    newCustomField: 'New custom field',
    editCustomField: 'Edit custom field'
  }
}
