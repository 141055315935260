import 'reflect-metadata'
// @ts-ignore
import VueLoading from 'vuex-loading'
import Vue from 'vue'
// @ts-ignore
import App from './App'
import router from './router'
import store from './store'
import i18n from './i18n'
import './filters'
import './directives'
import BootstrapVue from 'bootstrap-vue'
import Moment from 'moment'
import Toasted from 'vue-toasted'
import { extendMoment } from 'moment-range'

import { version } from './../package.json'

const __svg__ = { path: './components/assets/icons/*.svg', name: 'assets/images/[hash].sprite.svg' }
require('webpack-svgstore-plugin/src/helpers/svgxhr')(__svg__)

Vue.use(Toasted, {
  duration: 5000,
  position: 'bottom-right'
})
Vue.use(BootstrapVue)
Vue.use(VueLoading)

// @ts-ignore
Vue.prototype.$moment = extendMoment(Moment)

/* eslint-disable no-new */

new Vue({
  el: '#app',
  provide: {
    version
  },
  router,
  store,
  i18n,
  render: h => h(App),
  // @ts-ignore
  vueLoading: new VueLoading({ useVuex: true, moduleName: 'loading' })
})
