import api from '@/api'
import store from '@/store'
import { createActionHelpers } from 'vuex-loading'
const { startLoading } = createActionHelpers({ moduleName: 'loading' })

window.addEventListener('storage', (e) => {
  if (e.key === 'lastRequestTimestamp') {
    store.commit('auth/SET_LAST_REQUEST_TIME', { time: parseInt(e.newValue) })
  }
})

export default {

  actions: {
    async acceptOffer ({ commit, dispatch }, data) {
      try {
        const result = await startLoading(dispatch, 'auth:login', () => api.acceptOffer({ data }))
        return result.data
      } catch (e) {
        return e
      }
    },
    async completeSigning ({ commit, dispatch }, data) {
      try {
        const result = await startLoading(dispatch, 'auth:login', () => api.completeSigning({ data }))
        return result.data
      } catch (e) {
        return e
      }
    }
  }
}
