import accept from '@/router/accept'
import Vue from 'vue'
import Router from 'vue-router'

// import Store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    ...accept
  ]
})

// const { state } = Store

// router.beforeEach((to, from, next) => {
//   // const isLoggedIn = state.auth.isLoggedIn
//   // const acceptRegexp = /(^\/accept-offer)/
//   // console.log(to)
//   // console.log(to.path.match(acceptRegexp))
//   console.log(next)
//   console.log(router)
//   console.log(to.path)
//   console.log(to.path.includes('/accept-offer'))
//   // if (to.path.match(acceptRegexp)) {
//   if (to.path.includes('/accept-offer')) {
//     // if (isLoggedIn) {
//     //   next('/')
//     // } else {
//     next()
//     // }
//   } else {
//     // if (isLoggedIn) {
//     //   next()
//     // } else {
//     next({
//       name: 'acceptOffer',
//       query: { ...to.query }
//     })
//     // }
//   }
// })

export default router
