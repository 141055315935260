import merge from 'lodash/merge'
import pick from 'lodash/pick'
import find from 'lodash/find'
import first from 'lodash/first'

export { feePercentage, convertFee, feeDisabled, feeDefault, convertFeeRange, feeEditable } from './feeUtils'

export const toUpperCase = (value) => (value ?? '').replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase()

export const ucFirst = (value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()

export const calculationTypes = (fee, feeConfigs, calcTypes, useId = false) => {
  const { feeType } = fee
  const feeTypeLocal = Object.keys(feeConfigs).includes(feeType?.code ?? feeType) ? feeType?.code ?? feeType : 'CUSTOM_FEE'
  const feeCalculationTypePossible = feeConfigs[feeTypeLocal]
  return Object.values(calcTypes)
    .filter(({ code }) => feeCalculationTypePossible?.includes(code) ?? code)
    .map(({ human, code, id }) => ({ text: human, value: useId ? id : code }))
}

export const sortClassifiers = (classifiers) => {
  const list = JSON.parse(JSON.stringify(classifiers))
  const sortList = list.sort(function (a, b) {
    const nameA = a.text
    const nameB = b.text
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })
  return sortList
}

export const findCustomerMainContact = (contacts = [], defaultValue) =>
  (contacts.find(({ main }) => main) ?? contacts[0])?.value ?? defaultValue
export const findCustomerMainAddress = (addresses = [], defaultValue) =>
  (addresses.find(({ isDefault }) => isDefault) ?? addresses[0]) ?? defaultValue
export const findCustomerMainAccount = (accounts = [], defaultValue) =>
  (accounts.find(({ generalAccount }) => generalAccount) ?? accounts[0])?.accountExternalNumber ?? defaultValue

export const customerDefaultAddress = customer => find(customer?.addresses, { isDefault: true }) ?? first(customer?.addresses) ?? {}

export const customerName = customer => {
  if (!customer) {
    return ''
  }

  const { customerName, firstName, lastName } = customer

  return customerName ?? [firstName, lastName].filter(Boolean).join(' ')
}

export const pathBuilder = (strings, ...params) => {
  const [rootPath, itemPath] = strings
  const [rootId, action, actionId] = params
  return [rootPath, rootId, itemPath, action, actionId].filter(Boolean).join('/')
}

export const mergeFields = (src, dest) => merge({}, dest, { ...pick(src, Object.keys(dest)) })

export const createBlobFromObject = object => new Blob([JSON.stringify(object)], { type: 'application/json' })

export const parseStringBoolean = (stringValue) => JSON.parse(stringValue ?? 'false')

export const getStatuesQuery = (statuses) => {
  if (Array.isArray(statuses)) return statuses
  return [statuses].filter(Boolean)
}

export const parseSellerData = (seller) => ({
  sellerId: seller?.id ?? seller?.customerId ?? seller?.sellerId ?? '',
  sellerContactName: customerName(seller),
  sellerPhone: findCustomerMainContact(seller?.phoneNumbers, seller?.phoneNumber ?? seller?.phone),
  sellerEmail: findCustomerMainContact(seller?.emails, seller?.email)
})
