import Vue from 'vue'
import i18next from 'i18next'
import VueI18Next from '@panter/vue-i18next'
import moment from 'moment'

import { formatDate } from './formatDate'
import { formatDatetime } from './formatDatetime'
import { formatTime } from '@/i18n/formatTime'

import en from './en'
import et from './et'
import ru from './ru'

Vue.use(VueI18Next)

const dateFormats = {
  en: 'DD-MMM-YYYY',
  et: 'DD.MM.YYYY',
  ru: 'DD.MM.YYYY'
}

i18next.init({
  lng: sessionStorage.getItem('locale') || 'en',
  resources: {
    en: en,
    et: et,
    ru: ru
  },
  interpolation: {
    format (value, format) {
      if (format === 'date') return formatDate(...arguments)
      if (format === 'datetime') return formatDatetime(...arguments)
      if (format === 'time') return formatTime(...arguments)
      return value
    }
  }
})

const localize = (lang) => {
  moment.locale(lang)
  moment.defaultFormat = dateFormats[lang]
  document.documentElement.setAttribute('lang', lang)
}

localize(i18next.language)

i18next.on('languageChanged', (lng) => localize(lng))

export default new VueI18Next(i18next)

