import isNil from 'lodash/isNil'

const feeValuesKeys = ['feeMin', 'feeMax']

export const feePercentage = (feeCalcType) => feeCalcType ? /PERCENTAGE/i.test(feeCalcType) : undefined

export const convertFee = ({ feeValue, isPercentage, amountValue }) => {
  if (!feeValue) return 0
  if (isPercentage) {
    return feeValue / amountValue
  }
  return feeValue * amountValue
}

export const convertFeeRange = (fee = {}, amount) => {
  const { feeMin, feeMax, feeCalculationType } = fee
  if (feePercentage(feeCalculationType?.code ?? feeCalculationType)) {
    return {
      feeMin: feeMin / amount,
      feeMax: feeMax / amount
    }
  }
  return {
    feeMin,
    feeMax
  }
}

export const feeEditable = (fee) => !feeValuesKeys.every(valueKey => fee?.[valueKey] === fee?.feeAmount) && fee.feeOverride

export const feeDisabled = (fee) => feeValuesKeys.every(valueKey => !fee?.[valueKey])

export const feeDefault = (fee, value) => {
  if (feeDisabled(fee)) {
    return
  }
  if (!feeEditable(fee) || isNil(value)) {
    return fee.feeAmount
  } else {
    return value
  }
}

export const fee = ({ leasing = false, feeType = '', feeCalculationType = '' } = {}) => ({
  feeType: {
    classifier: 'feeTypeEntity',
    code: feeType
  },
  feeAmount: undefined,
  feeMax: undefined,
  feeMin: undefined,
  feeCalculationType: {
    classifier: 'feeCalculationType',
    code: feeCalculationType
  },
  feeOverride: false,
  ...leasing ? { withVat: false } : {}
})
