export default {
  common: {
    yes: 'Yes',
    no: 'No',
    edit: 'Edit',
    save: 'Save',
    send: 'Send',
    activate: 'Activate',
    remove: 'Remove',
    removing: 'Removing',
    cancel: 'Cancel',
    back: 'Back',
    close: 'Close',
    active: 'Active',
    inactive: 'Inactive',
    selectBlank: 'Please select',
    default: 'Default',
    min: 'Min',
    max: 'Max',
    step: 'Step',
    formatDate: '{{ value, date }}',
    formatDatetime: '{{ value, datetime }}',
    formatTime: '{{ value, time }}',
    enabled: 'Enabled',
    disabled: 'Disabled',
    required: 'This field is required',
    emailError: 'Value must be correct email address',
    apply: 'Apply',
    download: 'Download',
    confirm: 'Confirm',
    add: '+ Add new'
  },

  auth: {
    title: 'Login',
    prompt: 'Sign In to your account',
    login: 'Login',
    password: {
      title: 'Password',
      username: 'Username',
      password: 'Password',
      forgot: 'Forgot your credentials?'
    },
    card: {
      title: 'ID-Card',
      p: 'Insert ID-Card into the card reader and press "Login"'
    },
    mobile: {
      title: 'Mobile-ID',
      p1: 'Your control code is: {{code}}',
      p2: 'Message sent to your telephone. Check the code!',
      phone: 'Mobile number'
    },
    smart: {
      title: 'Smart-ID',
      p1: 'Your control code is: {{code}}',
      p2: 'Message sent to your telephone. Check the code!',
      idCode: 'ID Code'
    },
    idLogout: {
      title: 'Warning',
      warning: 'Restart your browser!'
    }
  },

  menu: {
    search: 'Search...',
    home: 'Home',
    dashboard: 'Dashboard',
    applications: 'Applications',
    application: 'Application',
    applicationNew: 'New application',
    customers: 'Customers',
    customer: 'Customer',
    customerNew: 'New customer',
    loans: 'Loans',
    loan: 'Loan',
    loanNew: 'New loan',
    factoringContracts: 'Factoring contracts',
    factoringInvoices: 'Factoring invoices',
    factoringScheduleInvoices: 'Schedule invoices',
    factoring: 'Factoring',
    factoringNew: 'New factoring contract',
    deposits: 'Deposits',
    deposit: 'Deposit',
    depositNew: 'New deposit',
    debts: 'Debts',
    scoring: 'Scoring',
    scoringModels: 'Scoring models',
    scoringModelConfigs: 'Model configuration',
    scoringRequest: 'Request',
    newScoringRequest: 'New request',
    payments: 'Payments',
    paymentsIncoming: 'Incoming payments',
    paymentsOutgoing: 'Outgoing payments',
    payment: 'Payment',
    reports: 'Reports',
    reportsFinancial: 'Financial reports',
    reportsQuicksight: 'BI Dashboards',
    admin: 'Admin',
    products: 'Products',
    newProduct: 'New product',
    editProduct: 'Edit product',
    classifiers: 'Classifiers',
    settings: 'Settings',
    templates: 'Templates',
    newTemplate: 'New Template',
    editTemplate: 'Edit template',
    logs: 'Logs',
    dataAccessLog: 'Data access log',
    loginHistoryLog: 'Login history log',
    roleList: 'Roles',
    logout: 'Logout',
    systemNotifications: 'System notifications',
    automaticNotifications: 'Automatic notifications',
    newAutomaticNotification: 'New automatic notification',
    editAutomaticNotification: 'Edit automatic notification',
    migration: 'Data migrations',
    taxation: 'Taxation',
    newTaxation: 'New tax rate',
    editTaxation: 'Edit tax rate',
    customFields: 'Custom fields',
    customerFields: 'Customer fields',
    KYCCustomFields: 'KYC fields',
    newKYCCustomField: 'New KYC field',
    editKYCCustomField: 'Edit KYC field',
    newCustomerField: 'New customer field',
    editCustomerField: 'Edit customer field',
    newCustomField: 'New custom field',
    editCustomField: 'Edit custom field',

    variableInterest: 'Variable interest',
    variableInterestsList: 'Variable interests',
    interestRatesList: 'Interest rates'
  }
}
