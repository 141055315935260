export default [
  {
    path: '/application/:id/accept-offer',
    name: 'acceptOffer',
    component: () => import(/* webpackChunkName: "Auth" */'@/views/accept/acceptOffer')
  },
  {
    path: '/contract/:id/complete-signing',
    name: 'acceptOffer',
    component: () => import(/* webpackChunkName: "Auth" */'@/views/accept/completeSigning')
  }
]
