<template lang="pug">
fi-modal.fi-confirm-modal(
  v-model           = 'showModal'
  ref               = 'modal'
  header-bg-variant = 'warning'
  :title            = 'title || $t("title")'
  centered
  @hidden           = 'hiddenCallback'
  @close            = 'close'
)
  p.text-dark.mb-0 {{ message || $t('message')}}
  template(#modal-footer)
    button.btn.btn-warning(
      :disabled = 'loading'
      @click.prevent.stop = 'onOk'
    ) {{ $t('common:yes') }}
      i.fa.fa-spinner.fa-pulse(v-if = 'loading')
    button.btn.btn-secondary(
      :disabled = 'loading'
      @click.prevent.stop = 'close'
    ) {{ $t('common:cancel') }}
</template>


<script>
import FiModal from '@/components/FiModal'

export default {
  name: 'fi-confirm-modal',

  components: { FiModal },

  props: {
    loader: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    okCallback: {
      type: Function,
      required: true
    },
    hiddenCallback: {
      type: Function,
      default: () => {}
    },
    value: {
      type: Boolean,
      default: true
    }
  },

  i18nOptions: {},

  computed: {
    showModal: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    loading () {
      return this.$vueLoading.isLoading(this.loader)
    }
  },

  methods: {
    close (event) {
      if (this.loading) {
        event.preventDefault()
      }

      // eslint-disable-next-line no-unused-expressions
      this.$refs.modal?.hide()
    },
    async onOk () {
      await this.okCallback({
        done: () => {
          // eslint-disable-next-line no-unused-expressions
          this.close()
        }
      })
    }
  }
}
</script>


<i18n>
en:
  title:   'Confirmation'
  message: 'Are you sure?'
et:
  title:   'Confirmation'
  message: 'Are you sure?'
ru:
  title:   'Confirmation'
  message: 'Are you sure?'
</i18n>
