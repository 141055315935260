<template lang="pug">
router-view
</template>


<script>
export default {
  name: 'app'
}
</script>


<style lang='scss'>// Import Main styles for this application
@import 'scss/style';
</style>
