import axios from 'axios'
import store from '@/store'

const connection = axios.create({
  baseURL: window.location.origin + '/api',
  withCredentials: true
})

// ID card authentication is under id.<env>.bankish.eu domain
const idAuthConnection = axios.create({
  baseURL: window.location.origin.replace(/(\/\/)(\w*)(\.)/gmi, '$1id$3') + '/api',
  withCredentials: true
})

connection.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token')
  if (token) config.headers.Authorization = `Bearer ${token}`
  return config
})


export const browserDownload = (data, filename) => {
  if (typeof window.navigator.msSaveBlob === 'function') {
    window.navigator.msSaveBlob(data, filename)
  } else {
    const fileURL = window.URL.createObjectURL(data)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = fileURL
    tempLink.setAttribute('download', filename)

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(fileURL)
  }
}

const downloadFile = async (url) => {
  const { headers, data } = await connection.get(url, { responseType: 'blob' })
  const filename = headers['content-disposition']?.match('filename="(.*)"') ? headers['content-disposition']?.match('filename="(.*)"')[1] : headers['content-disposition']?.match('filename=(.*)')[1] || 'file'
  const file = new Blob([data], { type: headers['content-type'] })
  browserDownload(file, filename)
  return true
}

// ONLY FOR DEVELOPMENT!!!
if (process.env.NODE_ENV === 'development') {
  const devCert = `-----BEGIN CERTIFICATE-----
MIIF2DCCA8CgAwIBAgIQI4TRphG+kUpbBsgOnnQEtDANBgkqhkiG9w0BAQsFADBjMQswCQYDVQQG
EwJFRTEiMCAGA1UECgwZQVMgU2VydGlmaXRzZWVyaW1pc2tlc2t1czEXMBUGA1UEYQwOTlRSRUUt
MTA3NDcwMTMxFzAVBgNVBAMMDkVTVEVJRC1TSyAyMDE1MB4XDTE4MDUyNDE0MTEyNloXDTIzMDUy
MzIwNTk1OVowgZExCzAJBgNVBAYTAkVFMQ8wDQYDVQQKDAZFU1RFSUQxFzAVBgNVBAsMDmF1dGhl
bnRpY2F0aW9uMSEwHwYDVQQDDBhQSUxMRVIsTUFSRUssMzcyMTIxNTAyMjkxDzANBgNVBAQMBlBJ
TExFUjEOMAwGA1UEKgwFTUFSRUsxFDASBgNVBAUTCzM3MjEyMTUwMjI5MHYwEAYHKoZIzj0CAQYF
K4EEACIDYgAEdkRm6wOM6IToKZ3vNg91H3mY/02shdlpmxgOXZ7D0ETyZaHE7QtxUy6YZPCmLUkN
FmZNq1yuM/PefySzFtKPPO92Dz3f9sB4+rXaKo638BWnuN1xiHRD6lQnlp1OIUbMo4ICBTCCAgEw
CQYDVR0TBAIwADAOBgNVHQ8BAf8EBAMCA4gwUwYDVR0gBEwwSjA+BgkrBgEEAc4fAQEwMTAvBggr
BgEFBQcCARYjaHR0cHM6Ly93d3cuc2suZWUvcmVwb3NpdG9vcml1bS9DUFMwCAYGBACPegECMCAG
A1UdEQQZMBeBFW1hcmVrLnBpbGxlckBlZXN0aS5lZTAdBgNVHQ4EFgQUEuuYubvuxT4Fo6ZwWVsj
BSBlUpwwIAYDVR0lAQH/BBYwFAYIKwYBBQUHAwIGCCsGAQUFBwMEMB8GA1UdIwQYMBaAFLOriLyZ
1WKkhSoIzbQdcjuDckdRMGEGCCsGAQUFBwEDBFUwUzBRBgYEAI5GAQUwRzBFFj9odHRwczovL3Nr
LmVlL2VuL3JlcG9zaXRvcnkvY29uZGl0aW9ucy1mb3ItdXNlLW9mLWNlcnRpZmljYXRlcy8TAkVO
MGoGCCsGAQUFBwEBBF4wXDAnBggrBgEFBQcwAYYbaHR0cDovL2FpYS5zay5lZS9lc3RlaWQyMDE1
MDEGCCsGAQUFBzAChiVodHRwOi8vYy5zay5lZS9FU1RFSUQtU0tfMjAxNS5kZXIuY3J0MDwGA1Ud
HwQ1MDMwMaAvoC2GK2h0dHA6Ly93d3cuc2suZWUvY3Jscy9lc3RlaWQvZXN0ZWlkMjAxNS5jcmww
DQYJKoZIhvcNAQELBQADggIBACoQSs3FtpQo91+f5qMkYp0k7LnCuHRmEI46f58Md+3NGZpJnoft
998NdYsL4/9NRl7F57iUnROAaQhEP35zFKzGbu9m2+XiswnZRRpwFOiyhfBBtso0axdbSDZAETIe
rcEt8rRUnt6h6ExGsye7S6vdNLxPq0HMcxNyY+w/dfGbLb+cy8Cm9j59U3XQne0nUEUSotZsgpve
Gy3wGrdX0AGXaQd+mnlzgyLwEH5JocBVe4bSHdiF4pav5A/CgBJ1BUoMrVbE7iTzSpvmaSgqrO5Z
7zisCqIgjCbuhfK4lR+FHRe53HQw3NdvWNcEPWZ4cTtJ18CwOIfg4AkOjkBTl/q7m1yBEe/YGOGb
aIdNYUPuM2FHFp8DwYba/ekQ86od6yjgBDedM+DExoRRyhFxSdcVuYV7qx0B3hU3UCFBskR7yuo6
NeGHCQxc+w6vcw3RI5V6RDOFAguTSvtA9w+hbLZQAmKYVF3RVwcEIX4SPMEI8od3XxPUHUdjEc4h
pM6Xy3Hvx3GRt2jtT1Phh49hTPqppeRnOG+RBC7PjRuwBBBzV+zjobddr9NYUEo0Ipul+lTQkYYu
FAbvn1V+MWyIW40Xwn0TREWG3qFgLhhXnvtHT5f1GNJV7dvI/4570O5XqswTbEzj+iquq/AQcgmz
F9YJsPbYYFlnmL2gSq2GmGjX
-----END CERTIFICATE-----`.replace(/\n/g, ' ')

  idAuthConnection.interceptors.request.use((config) => {
    if (store.state.auth.isIdCardAuth) {
      config.headers.common.SSL_CLIENT_CERT = devCert
      config.headers.common.SSL_CLIENT_VERIFY = 'SUCCESS'
    }
    return config
  })
}

export {
  connection,
  idAuthConnection,
  downloadFile
}
