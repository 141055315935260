import Vue from 'vue'
import store from '@/store'
import numeral from 'numeral'
import { ucFirst } from '@/helpers'

numeral.localeData().delimiters.thousands = ' '

export const moneyFilter = (value, format = '0,0.00 $') => {
  if (!value && value !== 0) return value
  numeral.localeData().currency.symbol = store.state.settings.settings.currencySymbol
  return `${numeral(value).format(format)}`
}

Vue.filter('money', moneyFilter)

Vue.filter('percent', (value, format = '0.00 %') => {
  if (!value && value !== 0) return value
  return numeral(value).format(format)
})

Vue.filter('ucFirst', ucFirst)

Vue.filter('sentenceCase', (string = '') => string.replace(/([A-Z])_([A-Z])/g, '$1 $2'))

Vue.filter('upperCase', (string = '') => string.toLocaleUpperCase())
