<template lang='pug'>
b-modal.fi-model(
  ref     = 'modal'
  v-model = 'showModal'
  v-bind  = '$attrs'
  v-on    = '$listeners'
  no-close-on-backdrop
  size    = 'lg'
)
  template(
    v-for         = '(_, slot) in $scopedSlots'
    v-slot:[slot] = 'props'
  )
    slot(
      :name  = 'slot'
      v-bind = 'props'
    )
</template>


<script>
export default {
  name: 'fi-modal',

  inheritAttrs: false,

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  methods: {
    hide () {
      const { hide } = this?.$refs?.modal ?? {}
      hide && hide()
    }
  }
}
</script>
